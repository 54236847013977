
function Header({ headerData }) {
    return (
        <div className="for-background">
            <div className="left-header-container">
                <div className="left-header">
                    <div className="coin-container">
                        <img src='/tlmlogo.png' style={{ width: '0.8rem', height: '0.8rem' }}></img>
                        <p>{parseFloat(headerData.totalTLM).toFixed(2)} [{parseFloat(headerData.totalClaim).toFixed(2)}] ➡️ {parseFloat(headerData.overAllTLM).toFixed(2)}</p>
                    </div>

                    <div className="coin-container">
                        <img src='/waxlogo.png' style={{ width: '0.8rem', height: '0.8rem' }}></img>
                        <p>{parseFloat(headerData.totalWax).toFixed(4)} [{parseFloat(headerData.refundingWax).toFixed(4)}] ➡️ {parseFloat(headerData.overAllWax).toFixed(4)} | SCPU: {parseFloat(headerData.stakeAll).toFixed(4)}</p>
                    </div>
                    <div className="coin-container">
                        <p>Bot: {headerData.botAlive} - Flag: {headerData.botFlag}</p>
                    </div>
                    <div className="coin-container">
                        <p>
                            ไอดีคอมม่อน: <span style={{ color: '#42bd41' }}>
                                {headerData.acommonuse}
                            </span> |
                            ไอดีคอมม่อน 7 บาท: <span style={{ color: '#42bd41' }}>
                                {headerData.acommonhalf}
                            </span> |
                            สล็อตคอมม่อน: <span style={{ color: '#42bd41' }}>
                                {headerData.aslotcommon}
                            </span>
                        </p>


                        {/* <p>ไอดีคอมม่อน: {headerData.acommonuse} | ไอดีคอมม่อน 7 บาท: {headerData.acommonhalf} | สล็อตคอมม่อน: {headerData.aslotcommon} </p> */}
                    </div>
                    <div className="coin-container">
                        <p>
                            ไอดีของสี: <span style={{ color: 'yellow' }}>
                                {headerData.auncommonuse}
                            </span> |
                            สล็อตของสี: <span style={{ color: 'yellow' }}>
                                {headerData.aslotuncommmon}
                            </span>
                        </p>
                    </div>
                </div>

                <h3>Anonymous Monitor 2025</h3>
                <div className="left-header">
                    <p>Port: {headerData.portName} ({headerData.timeRangeText})</p>
                    <p>AutoClaim: {headerData.autoClaim} @{headerData.claimAt}</p>
                    <p>Mainstake: {headerData.mainStake}</p>
                    <p>Version: {headerData.version}</p>

                    <p>
                        ไอดีคอมม่อน: <span style={{ color: '#42bd41' }}>
                            {headerData.commonuse}
                        </span> |
                        ไอดีคอมม่อน 7 บาท: <span style={{ color: '#42bd41' }}>
                            {headerData.commonhalf}
                        </span> |
                        สล็อตคอมม่อน: <span style={{ color: '#42bd41' }}>
                            {headerData.slotcommon}
                        </span>
                    </p>

                    <p>
                        ไอดีของสี: <span style={{ color: 'yellow' }}>
                            {headerData.uncommonuse}
                        </span> |
                        สล็อตของสี: <span style={{ color: 'yellow' }}>
                            {headerData.slotuncommmon}
                        </span>
                    </p>
                </div>
            </div>
        </div>

    );
}

export default Header;