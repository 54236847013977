import React, { useState } from 'react';
import toast from 'react-hot-toast';
import './SumCss.css';
import Prog from './Prog';
import ItemShow from './ItemShow';
import { Row, Col, Offcanvas, Form } from 'react-bootstrap';

const moment = require('moment-timezone');
function formatSeconds(seconds) {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  const parts = [];
  if (hours > 0) {
    parts.push(`${hours}hr`);
  }
  if (minutes > 0) {
    parts.push(`${minutes}m`);
  }
  if (seconds > 0 || parts.length === 0) {
    parts.push(`${parseInt(seconds)}s`);
  }

  return parts.join(' ');
}

function convertToThailandTime(dateString) {
  // Parse the input date string
  const date = moment(dateString);

  // Convert to Thailand time zone
  const thailandTime = date.tz('Asia/Bangkok');

  // Format the date in "MM-DD HH:MM:SS" format
  const formattedDate = thailandTime.format('M-D HH:mm:ss');

  return formattedDate;
}

const statusColors = {
  Mine: '#009600',
  CPU: '#AE0101',
  FLAG: 'orange',
  Sleep: '#015C6F',
};

const generateLevels = (p) => {
  const styles = {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    // marginBottom: "1px",
  };

  if (p >= 385440) {
    return <div style={{ ...styles, backgroundColor: '#b91c1c' }}></div>; // bg-red-600
  }
  if (p >= 146921) {
    return <div style={{ ...styles, backgroundColor: '#b91c1c' }}></div>; // bg-red-600
  }
  if (p >= 55843) {
    return <div style={{ ...styles, backgroundColor: '#b91c1c' }}></div>; // bg-red-600
  }
  if (p >= 21086) {
    return <div style={{ ...styles, backgroundColor: '#b91c1c' }}></div>; // bg-red-600
  }
  if (p >= 7853) {
    return <div style={{ ...styles, backgroundColor: '#b91c1c' }}></div>; // bg-red-600
  }
  if (p >= 2843) {
    return <div style={{ ...styles, backgroundColor: '#fbbf24' }}></div>; // bg-yellow-500
  }
  if (p >= 965) {
    return <div style={{ ...styles, backgroundColor: '#6d28d9' }}></div>; // bg-purple-600
  }
  if (p >= 284) {
    return <div style={{ ...styles, backgroundColor: '#06b6d4' }}></div>; // bg-cyan-500
  }
  if (p >= 66) {
    return <div style={{ ...styles, backgroundColor: '#374151' }}></div>; // bg-gray-700
  }
  return <div style={{ ...styles, backgroundColor: '#cbd5e1' }}></div>; // bg-gray-400
};

const CustomTable = ({ data, onSelect }) => {
  // State to manage which columns are visible
  const [visibleColumns, setVisibleColumns] = useState({
    no: true,
    account: true,
    cpu: true,
    net: true,
    ram: true,
    scpu: true,
    snet: true,
    bal: true,
    wax: true,
    land: true,
    bags: true,
    pts: true,
    lastMine: true,
    delay: true,
    wait: true,
    status: true,
    inventory: true,
  });

  // Function to toggle column visibility
  const toggleColumn = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectIds, setSelectedIds] = useState([]);
  const [startId, setStartId] = useState(null);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const selectAll = () => {
    if (!isSelectAll) {
      const ListIDs = data.map(i => i.NO);
      const selectAllId = ListIDs.filter(id => id !== "MAINSTAKE");
      onSelect(selectAllId);
      setSelectedIds(selectAllId);
    } else {
      onSelect([]);
      setSelectedIds([]);
    }
    setIsSelectAll(!isSelectAll);

  };

  const findRange = (end) => {
    const ListIDs = data.map(i => i.NO);
    const [base, endValue] = end.split('-');
    const baseIndex = ListIDs.indexOf(base);

    if (baseIndex === -1) {
      return [];
    }
    const endIndex = ListIDs.indexOf(endValue);

    if (endIndex === -1) {
      return [];
    }

    if (endIndex < baseIndex) {
      return ListIDs.slice(endIndex, baseIndex + 1).reverse();
    }

    return ListIDs.slice(baseIndex, endIndex + 1);
  };

  const parseIdRange = (input) => {
    const ListIDs = data.map(i => i.NO);
    const result = [];
    const parts = input.split(',');

    parts.forEach(part => {
      if (part.includes('-')) {
        const [start, end] = part.split('-').map(Number);
        for (let i = start; i <= end; i++) {
          if (ListIDs.includes(i)) {
            result.push(i);
          }
        }
      } else {
        result.push(Number(part));
      }
    });

    return result;
  };

  const onSelectId = (id, e) => {
    const { shiftKey, ctrlKey } = e;
    if (shiftKey) {
      if (startId === null) {
        setStartId(id)
      } else {
        const selectAll = Array.from(new Set([...selectIds, ...parseIdRange(`${startId}-${id}`)]));
        onSelect(selectAll);
        setSelectedIds(selectAll);
      }

    } else {
      if (startId !== id) {
        setStartId(id);
      }
      setSelectedIds(prevIds => {
        const newIds = prevIds.includes(id)
          ? prevIds.filter(i => i !== id)
          : [...prevIds, id];

        onSelect(newIds);
        return newIds;
      });
    }
  };

  const getIschecked = (id) => {
    return selectIds.includes(id);
  };

  // Button styles for the Show/🔓 functionality
  const getButtonStyle = (show, isHovered) => ({
    backgroundColor: show ? (isHovered ? '#1c1c1c' : '#2E2E2E') : (isHovered ? '#9f2f2f' : '#783C3C'),
    color: '#ffffff',
    border: `2px solid ${show ? '#4A4A4A' : '#A32D2D'}`, // Border color changes based on `show`
    borderRadius: '0.4rem',
    padding: '0.8rem',
    cursor: 'pointer',
    margin: '0.2rem',
    display: 'inline-flex',
    boxShadow: isHovered ? '0 4px 8px rgba(0, 0, 0, 0.5)' : '0 2px 4px rgba(0, 0, 0, 0.3)', // Shadow with increased intensity on hover
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease', // Transition for background color, shadow, and border color
  });

  return (
    <div>
      <button onClick={handleShow} style={{ borderRadius: '8rem', padding: '0.5rem', position: 'fixed', right: '1rem', bottom: '1rem', zIndex: 1000, backgroundColor: '#2E2E2E', fontSize: '0.8rem' }}>
        ⚙️
      </button>
      <Offcanvas show={show} onHide={handleClose} placement='bottom' style={{ backgroundColor: '#3E3E3E' }}>
        <Offcanvas.Header closeButton>
          <h3 style={{ color: 'white' }}>Table - Settings</h3>
        </Offcanvas.Header>
        <div className='button-container'>
          <button onClick={() => toggleColumn('no')} style={getButtonStyle(visibleColumns.no)}>{visibleColumns.no ? <> 🔓 No</> : <> 🔒 No</>}</button>
          <button onClick={() => toggleColumn('account')} style={getButtonStyle(visibleColumns.account)}>{visibleColumns.account ? <> 🔓 Account</> : <> 🔒 Account</>}</button>
          <button onClick={() => toggleColumn('cpu')} style={getButtonStyle(visibleColumns.cpu)}>{visibleColumns.cpu ? <> 🔓 CPU</> : <> 🔒 CPU</>}</button>
          <button onClick={() => toggleColumn('net')} style={getButtonStyle(visibleColumns.net)}>{visibleColumns.net ? <> 🔓 NET</> : <> 🔒 NET</>}</button>
          <button onClick={() => toggleColumn('ram')} style={getButtonStyle(visibleColumns.ram)}>{visibleColumns.ram ? <> 🔓 RAM</> : <> 🔒 RAM</>}</button>
          <button onClick={() => toggleColumn('scpu')} style={getButtonStyle(visibleColumns.scpu)}>{visibleColumns.scpu ? <> 🔓 SCPU</> : <> 🔒 SCPU</>}</button>
          <button onClick={() => toggleColumn('snet')} style={getButtonStyle(visibleColumns.snet)}>{visibleColumns.snet ? <> 🔓 SNET</> : <> 🔒 SNET</>}</button>
          <button onClick={() => toggleColumn('bal')} style={getButtonStyle(visibleColumns.bal)}>{visibleColumns.bal ? <> 🔓 BAL</> : <> 🔒 BAL</>}</button>
          <button onClick={() => toggleColumn('wax')} style={getButtonStyle(visibleColumns.wax)}>{visibleColumns.wax ? <> 🔓 WAX</> : <> 🔒 WAX</>}</button>
          <button onClick={() => toggleColumn('land')} style={getButtonStyle(visibleColumns.land)}>{visibleColumns.land ? <> 🔓 Land</> : <> 🔒 Land</>}</button>
          <button onClick={() => toggleColumn('bags')} style={getButtonStyle(visibleColumns.bags)}>{visibleColumns.bags ? <> 🔓 Bags</> : <> 🔒 Bags</>}</button>
          <button onClick={() => toggleColumn('pts')} style={getButtonStyle(visibleColumns.pts)}>{visibleColumns.pts ? <> 🔓 Pts</> : <> 🔒 Pts</>}</button>
          <button onClick={() => toggleColumn('lastMine')} style={getButtonStyle(visibleColumns.lastMine)}>{visibleColumns.lastMine ? <> 🔓 LastMine</> : <> 🔒 LastMine</>}</button>
          <button onClick={() => toggleColumn('delay')} style={getButtonStyle(visibleColumns.delay)}>{visibleColumns.delay ? <> 🔓 Delay</> : <> 🔒 Delay</>}</button>
          <button onClick={() => toggleColumn('wait')} style={getButtonStyle(visibleColumns.wait)}>{visibleColumns.wait ? <> 🔓 Wait</> : <> 🔒 Wait</>}</button>
          <button onClick={() => toggleColumn('status')} style={getButtonStyle(visibleColumns.status)}>{visibleColumns.status ? <> 🔓 Status</> : <> 🔒 Status</>}</button>
          <button onClick={() => toggleColumn('inventory')} style={getButtonStyle(visibleColumns.inventory)}>{visibleColumns.inventory ? <> 🔓 Inventory</> : <> 🔒 Inventory</>}</button>
        </div>
        {/* <div className="exit-button" onClick={handleLogout}>
            <img src="/exitdoor.png" alt="Exit Door" className="exit-icon" />
            <span>Logout</span>
        </div> */}
      </Offcanvas>
      <table className="custom-table">
        <thead>
          <tr>
            <th><Form.Check aria-label={`selectall`} onClick={selectAll} /> Select ({selectIds.length})</th>
            {visibleColumns.no && <th>No</th>}
            {visibleColumns.account && <th>Account</th>}
            {visibleColumns.cpu && <th>CPU</th>}
            {visibleColumns.net && <th>NET</th>}
            {visibleColumns.ram && <th>RAM</th>}
            {visibleColumns.scpu && <th>SCPU</th>}
            {visibleColumns.snet && <th>SNET</th>}
            {visibleColumns.bal && <th>TLM</th>}
            {visibleColumns.wax && <th>WAX</th>}
            {visibleColumns.land && <th>Land</th>}
            {visibleColumns.bags && <th>Bags</th>}
            {visibleColumns.pts && <th>Pts</th>}
            {visibleColumns.lastMine && <th>LastMine</th>}
            {visibleColumns.delay && <th>Delay</th>}
            {visibleColumns.wait && <th>Wait</th>}
            {visibleColumns.status && <th>Status</th>}
            {visibleColumns.inventory && <th>Inventory</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} >
              {/* style={{"backgroundColor": "red"}} */}
              {row.NO !== "RESULT" && row.NO !== "MAINSTAKE" ? (<td><Form.Check aria-label={`selectId-${index}`} checked={getIschecked(row.NO)} onClick={(e) => onSelectId(row.NO, e)} /></td>) : (<td>&nbsp;</td>)}
              {visibleColumns.no && (row.NO !== "RESULT" ? <td>{row.NO}</td> : (<td>&nbsp;</td>))}
              {visibleColumns.account &&
                row.NO !== "RESULT" ? (
                <td style={{ color: row.flagged ? 'red' : '' }} onClick={() => {
                  navigator.clipboard.writeText(row.Account);
                  toast.success(`copy account ${row.Account} to clipboard success!`, { duration: 2000 });
                }}>
                  <div className='justify-content-center' style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                    {row.NO !== "MAINSTAKE" && generateLevels(row.Pts[1])}
                    <div style={{ marginBottom: '6px' }}>{row.Account}</div>
                  </div>
                  {/* <Row > */}


                  {/* {row.Account} */}

                  {/* </Row> */}

                </td>
              ) : (<td>&nbsp;</td>)
              }
              {visibleColumns.cpu && row.NO !== "RESULT" ? <td className='progress-container'><Prog percent={row.CPU} /></td> : <td>&nbsp;</td>}
              {visibleColumns.net && row.NO !== "RESULT" ? <td className='progress-container'><Prog percent={row.NET} /></td> : <td>&nbsp;</td>}
              {visibleColumns.ram && row.NO !== "RESULT" ? <td className='progress-container'><Prog percent={row.RAM} /></td> : <td>&nbsp;</td>}
              {visibleColumns.scpu && <td>{row.SCPU[0]} <span style={{ color: '#8B9600' }}>{row.SCPU[1] > 0 ? `+${row.SCPU[1]}` : ''}</span></td>}
              {visibleColumns.snet && <td>{row.SNET[0]} <span style={{ color: '#8B9600' }}>{row.SNET[1] > 0 ? `+${row.SNET[1]}` : ''}</span></td>}
              {visibleColumns.bal && (
                row['NO'] === "MAINSTAKE" ? (
                  <td>{row.BAL[0]}</td>
                ) : (
                  <td>
                    {row.BAL[0]} <span style={{ color: '#009600' }}>[{row.BAL[1]}]</span>
                    <span style={{ color: '#8B9600' }}>
                      {(new Date() > new Date(row.BAL[2]))
                        ? ``
                        : ` [${formatSeconds((new Date(row.BAL[2]).getTime() - new Date().getTime()) / 1000)}]`
                      }
                    </span>
                  </td>
                )
              )}
              {visibleColumns.wax && <td>{row.WAX[0]} <span style={{ color: '#009600' }}>{row.WAX[1] ? `[${parseFloat(row.WAX[1].cpu_amount) + parseFloat(row.WAX[1].net_amount)}]` : ''}</span>
                <span style={{ color: '#8B9600' }}>{row.WAX[1] ? ` [${formatSeconds((new Date(row.WAX[1].request_time + '.000Z').getTime() + 259200000 - new Date().getTime()) / 1000)}]` : ''}</span></td>}
              {visibleColumns.land && <td>{row['NO'] !== "MAINSTAKE" && row.Land}</td>}
              {visibleColumns.bags && <td><ItemShow data={row.Bags} isBag={true}></ItemShow></td>} {/* input data row.Bags = [{img_code: code, amount: 1 }, {img_code: code, amount: 1 }....]*/}
              {visibleColumns.pts && (<td><span style={{ color: row.Pts[0] > 22800 ? '#8B9600' : 'inherit' }}>{row['NO'] !== "MAINSTAKE" && row.Pts[0]}{row['NO'] !== "MAINSTAKE" && '/'}</span><span style={{ color: row.Pts[1] > 22800 ? '#8B9600' : 'inherit' }}>{row['NO'] !== "MAINSTAKE" && row.Pts[1]}</span></td>)}
              {visibleColumns.lastMine && <td>
                <div className='justify-content-center' style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>{row.NO !== "MAINSTAKE" && convertToThailandTime(row.LastMine + ".000Z")}</div>
                  <div style={{ color: 'green' }}>{row.NO !== "MAINSTAKE" && row.lastminetlm}</div>
                </div>
              </td>}
              {visibleColumns.delay && <td>{row['NO'] !== "MAINSTAKE" && formatSeconds(row.Delay)}</td>}
              {visibleColumns.wait && <td style={{ color: row.Wait > 60 ? '#AE0101' : 'inherit' }}>{row['NO'] !== "MAINSTAKE" && formatSeconds(row.Wait)}</td>}
              {visibleColumns.status && (
                <td style={{ color: statusColors[row.Status] }}>
                  {row.Status}
                </td>
              )}
              {visibleColumns.inventory && <td><ItemShow data={row.Inventory}></ItemShow></td>} {/* input data row.Inventory = [{img_code: code, amount: 30 }, ....]*/}
            </tr>
          ))}
        </tbody>
      </table>




    </div >
  );
};

export default CustomTable;